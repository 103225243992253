@charset 'UTF-8';

.header{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: $green;
  display: flex;
  justify-content: space-between;
  z-index: 999;

  &__left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 60%;
    max-width: 835px;
  }
  &__right{
    display: flex;
    justify-content: flex-end;
    width: 40%;
    max-width: 535px;
    margin-left: auto;
  }
  .txtH1{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 142px;
    @include font(12,20,0,500);
    color: #fff;
    padding-left: 30px;
  }
  &__logo{
    width: 184px;
    margin-left: 15px;
  }
  &__menu{
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    width: calc(100% - 320px);
    li{
      margin-left: 20px;
      a{
        display: block;
        @include font(16,27,0,500);
        color: #fff;
      }
    }
  }
  &__lang{
    margin-right: 35px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 90px;
    align-items: center;
    a{
      display: block;
      font-family: $lato;
      @include font(15,18,0,500);
      color: #fff;
      margin-left: 15px;
      &:first-child{
        margin-left: 0;
      }
      &.active{
        border-bottom: 1px solid #fff;
      }
    }
  }
  .btnYellow{
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 160px;
    height: 100%;
    min-height: 90px;
  }
  &__tel{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 240px;
    background: #fff;
    font-family: $lato;
    @include font(24,29,.08,bold);
    color: $yellow !important;
    padding: 0 15px;
  }
  .menuSP{
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition-timing-function: cubic-bezier(.075,.82,.165,1);
    transition-duration: 1s;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
    text-align: center;
    &__icon {
      &, &:before, &:after {
        right: calc(50% - 8.5px);
        height: 2px;
        width: 30px;
        background: #fff;
        display: block;
        @include transition_c(all .3s);
      }
      &:before, &:after {
        position: absolute;
        content: '';
      }
      & {
        position: relative;
        top: 36px;
        left: calc(50% - 15px);
      }
      &::before {
        top: -8px;
        left: 0;
      }
      &::after {
        top: 8px;
        left: 0;
      }
    }
    &.active{
      .menuSP__icon{
        &, &:before, &:after {
          // background: $green;
        }
        &{
          @include transform_c(rotate(45deg));
        }
        &:before{
          top: 0;
          @include transform_c(rotate(90deg));
        }
        &:after{
          display: none;
        }
      }
      .menuSP__text{
        color: $color_body;
      }
    }
  }
  .menuSP_wrap{
    position: fixed;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100%;
    background: $green;
    padding: 80px 20px;
    transition: left 0.15s linear;
    overflow: scroll;
    &__inner{
      display: flex;
      flex-direction: column;
    }
    .txtH1{
      text-align: center;
      width: 100%;
      padding-left: 0;
      flex-basis: auto;
      br{
        display: none;
      }
    }
    .header__logo{
      display: block;
      margin: 20px auto 30px;
    }
    .header__menu{
      width: 100%;
      flex-direction: column;
      margin-bottom: 20px;
      text-align: center;
      padding-left: 0;
      li{
        margin-left: 0;
        margin-bottom: 10px;
      }
    }
    .header__lang{
      flex-basis: auto;
      justify-content: center;
      margin-bottom: 30px;
      margin-right: 0;
    }
    .btnYellow{
      width: 100%;
      max-width: none;
      min-height: 0;
      flex-basis: auto;
      height: 70px;
      margin-bottom: 30px;
    }
    .header__tel{
      flex-basis: 70px;
    }

  }
  @include screen(1025px, 1400px){
    flex-flow: row wrap;
    .txtH1{
      padding-left: 15px;
    }
    &__left{
      width: 50%;
      .header__menu{
        padding: 0 15px;
        li{
          a{
            @include font-size(15);
          }
        }
      }
    }
    &__right{
      width: 50%;
    }
  }
  @include screen(768px, 1024px){
    flex-flow: row wrap;
    .txtH1{
      padding-left: 15px;
    }
    &__left{
      width: 100%;
      padding: 10px 0;
      .header__menu{
        padding: 0 15px;
        li{
          a{
            @include font-size(15);
          }
        }
      }
    }
    &__right{
      width: 100%;
    }
  }
  @include SP{
    padding: 15px 0;
    &__left{
      .header__logo{
        width: auto;
        img{
          height: 50px;  
        }
      }
      .txtH1,.header__menu{
        display: none;
      }

    }
    &__right{
      display: none;
    }
  }
}
#wrap{
  padding-top: 90px;
  @include SP{
    padding-top: 80px;
  }
}
body.menuOpened{
  overflow: hidden;
  .header{
    .menuSP_wrap{
      left: 0;
    }
  }
}