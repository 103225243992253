@charset 'UTF-8';

.footer{
  padding: 45px 0 40px;
  background: $green;
  color: #fff;
  .container{
    max-width: 1320px;
  }
  .footerWrap{
    display: flex;
    justify-content: space-between;
    margin: 0 -45px;
    .col{
      padding: 0 45px;
      .txtH1{
        @include font(14,20,0,500);
      }
      .footerLogo{
        display: block;
        max-width: 242px;
        width: 100%;
        margin: 22px 0 15px;
      }
      a{
        color: #fff;
      }
      p{
        @include font(14,31,0,500);
        &.txt{
          @include ffYG;
        }
        &.copyright{
          @include font(12,15,0,400);
          font-family: $lato;
          margin-top: 15px;
        }
      }
      .footerMenu{
        margin-top: 5px;
        li{
          margin-bottom: 15px;
          a{
            @include font(14,31,0,500);
            @include ffYG;
            color: #fff !important;
            display: inline-block;
            position: relative;
            &:before{
              content: '';
              width: 100%;
              height: 1px;
              background: #fff;
              position: absolute;
              left: 0;
              bottom: 4px;
            }
          }
        }
      }
      .btnYellow{
        width: 100%;
        max-width: none;
        margin-bottom: 25px;
        margin-top: 12px;
        @include font-size(16);
        span{
          justify-content: flex-start;
          padding-left: 15px;
        }
      }
      .tel{
        &__text{
          @include ffYG();
        }
        &__number{
          a{
            @include font(24,29,.08,700);
            font-family: $lato;
            color: #fff !important;
            span{
              @include font(14,17,.08,400);
            }
          }
        }
      }
      .btnSocial{
        display: flex;
        margin: 13px -5px 20px;
        li{
          width: 50%;
          padding: 0 5px;
          a{
            display: flex;
            align-items: center;
            height: 60px;
            border: 1px solid #fff;
            color: #fff;
            span{
              @include font(16,30,0, 500);
              padding-left: 70px;
              display: block;
              height: 30px;
              width: 100%;
            }
            &.facebook{
              span{
                background: url(../img/common/icon/ico_facebook_white.svg) left 22px center/ 30px 30px no-repeat;
              }
            }
            &.instagram{
              span{
                background: url(../img/common/icon/ico_instagram_white.svg) left 22px center/ 30px 30px no-repeat;
              }
            }
          }
        }
      }
      .btnBanner{
        img{
          display: block;
          width: 100%;
        }
      }
      &--1{
        max-width: 440px;
        padding-right: 40px;
      }
      &--2{
        padding-left: 40px;
      }
      &--3{
        max-width: 392px;
      }
      &--4{
        max-width: 440px;
      }
      &.borderRight{
        position: relative;
        &:after{
          content: '';
          width: 1px;
          height: calc(100% - 60px);
          background: #fff;
          position: absolute;
          top: 12px;
          right: 0;
        }
      }
    }
  }
  @include screen(768px, 1400px){
    .container{
      padding: 0;
    }
    .footerWrap{
      flex-flow: row wrap;
      margin: 0;
      .col{
        max-width: none;
        width: 50%;
        margin-bottom: 30px;
        padding: 0 20px 20px;
        &:nth-child(3),&:nth-child(4){
          margin-bottom: 0;
          padding-bottom: 0;
        }
        &:after{
          display: none;
        }
      }
    }
  }
  @include SP{
    padding: 45px 0 13px;
    .footerWrap{
      flex-flow: row wrap;
      margin: 0;
      .col{
        padding: 0;
        width: 100%;
        max-width: none;
        padding: 0 0 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(255,255,255,.5);
        &:after{
          display: none;
        }
        &:last-child{
          margin-bottom: 0;
          border-bottom: 0;
        }
        &--1{
          text-align: center;
          .footerLogo{
            margin: 22px auto 15px;
          }
        }
        &--2{
          text-align: center;
        }
        @include max-screen(374px){
          .tel{
            &__text{
              @include font-size(13);
            }
            &__number{
              a{
                @include font-size(22);
              }
            }
          }
          .btnSocial{
            li{
              a{
                span{
                  @include font-size(14);
                  padding-left: 58px;
                  background-size: left 16px center;
                }
              }
            }
          }
        }
      }
      .copyright{
        text-align: center;
        margin-top: 30px !important;
      }
    }
  }
}