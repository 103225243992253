@charset 'UTF-8';

.c-btn {
  display: block;
  text-align: center;
  span {
    display: inline-block;
  }
}

.the-mv{
  position: relative;
  padding-bottom: 65px;
  overflow: hidden;
  @include SP{
    padding-bottom: 40px;
  }
  .bg{
    height: 560px;
    background: center/cover no-repeat;
    margin-bottom: 62px;
    @include PC{
      min-height: 560px;
      max-height: 600px;
    }
    @include SP{
      height: auto;
      min-height: 0;
      @include aspect-ratio(1500,560);
      margin-bottom: 0;
    }
  }
  .container{
    position: relative;
  }
  .outside{
    max-width: 1230px;
    padding: 0 15px;
    margin: 0 auto;
    @include absolute(0,100%,600px,100px,'','',50%);
    @include PC{
      @include transform_c(translateX(-50%));
      min-height: 530px;
      max-height: 580px;
      z-index: 99;
    }
    @include SP{
      position: static;
      height: auto;
      margin-top: -30px;
    }
  }
  .tag-name{
    color: #fff;
    width: 200px;
    background-color: $yellow;
    padding: 66px 20px;
    position: relative;
    height: 100%;
    font-size: 0;
    box-shadow: 2px 2px 10px rgba($color: #000000, $alpha: 0.5);
    @include screen(768px,1300px){
      width: 150px;
    }
    @include SP{
      width: 100%;
      height: auto;
      padding: 20px 50px;
    }
    &:before,
    &:after{
      @include absolute('',47px,47px,'','','','');
      background: url(../img/common/icon/ico_conner_white.svg) center/cover no-repeat;
      @include SP{
        width: 30px;
        height: 30px;
      }
    }
    &:before{
      top: 20px;
      left: 20px;
      @include SP{
        top: 10px;
        left: 10px;
      }
    }
    &:after{
      bottom: 20px;
      right: 20px;
      @include transform_c(rotate(180deg));
      @include SP{
        bottom: 10px;
        right: 10px;
      }
    }
    .inside{
      position: relative;
      height: 100%;
      &:before{
        @include absolute('',1px,calc(100% + 66px),0,'','',50%);
        background-color: #fff;
        @include SP{
          @include absolute('',100%,1px,auto,10px,'',0);
        }
      }
    }
    .jp,
    .en{
      margin: 0 auto;
      background-color: $yellow;
      position: relative;
      z-index: 1;
      display: flex;
      width: 100%;
      align-items: center;
      @include PC{
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
      }
    }
    .jp{
      @include font(36,36,0,600);
      padding: 27px 0 14px;
      @include PC{
        &:before{
          @include absolute('',13px,13px,0,'','',calc(50% - 6px));
          border: 1px solid #fff;
          border-radius: 50%;
        }
      }
      @include SP{
        background-color: transparent;
        padding: 0 0 10px;
        @include font(25,25,'');
      }
    }
    .en{
      @include font(22,24,0,500);
      padding-bottom: 10px;
      @include SP{
        display: inline-block;
        padding-bottom: 0;
        padding-right: 10px;
        font-size: 16px;
      }
    }
  }
  .script{
    max-width: 1230px;
    margin: 0 auto;
    padding: 0 165px;
    text-align: center;
    position: relative;
    z-index: 2;
    @include SP{
      margin-top: 25px;
      padding: 0 15px;
    }
    .ttl{
      @include font(22,37,0,600);
      margin-bottom: 25px;
      @include SP{
        font-size: 18px;
        margin-bottom: 0;
      }
    }
    .content{
      @include font(15,25,0,500);
      @include SP{
        font-size: 12px;
        letter-spacing: -0.05em;
      }
    }
  }
}

.the-ttl{
  text-align: center;
  position: relative;
  margin-bottom: 38px;
  &:before,
  &:after{
    @include absolute('',calc(50% - 110px),1px,calc(50% + 15px),'','','');
    background-color: rgba($color: #333, $alpha: 0.5);
    @include SP{
      width: calc(50% - 80px);
    }
  }
  &:before{
    left: 0;
  }
  &:after{
    right: 0;
  }
  .en{
    @include font(18,24,0,500);
    color: rgba($color: #333, $alpha: 0.5);
    display: block;
    @include SP{
      font-size: 13px;
    }
  }
  .jp{
    @include font(34,'',0,500);
    @include SP{
      font-size: 26px;
    }
  }
}

.the-ttl-corner{
  text-align: center;
  .txt{
    display: inline-block;
    max-width: 586px;
    position: relative;
    padding: 15px;
    width: 100%;
    @include SP{
      padding: 15px 35px;
    }
    &:before,
    &:after{
      @include absolute('',46px,46px,'','','','');
      background: url(../img/common/icon/ico_conner.svg) center/cover no-repeat;
      @include SP{
        width: 30px;
        height: 30px;
      }
    }
    &:before{
      top: 0;
      left: 0;
    }
    &:after{
      bottom: 0;
      right: 0;
      @include transform_c(rotate(180deg));
    }

    .en{
      @include font(24,27,0);
      color: rgba($color: #333, $alpha: 0.6);
      display: block;
      @include SP{
        font-size: 16px;
      }
      @include IP5{
        font-size: 14px;
      }
    }
    .jp{
      font-size: 40px;
      @include SP{
        font-size: 26px;
      }
      @include IP5{
        font-size: 22px;
      }
    }
  }
}

.popup_imgs{
  display: block;
  height: 0;
  position: fixed;
  width: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 999;
  &.active{
    display: table;
    height: 100%;
  }
  &::before{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    z-index: -1;
  }
  .wrap_bg{
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }

  .wrap_container{
    max-width: 1054px;
    width: 100%;
    margin: auto;
  }

  .btn_close{
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    margin: 0 0 0 auto;
    cursor: pointer;
    &:hover{
      opacity: 0.5;
      transition: 0.5s ease;
    }
    @include SP{
      padding-right: 40px;
    }
    &:after{
      content: '';
      position: absolute;
      width: 40px;
      height: 40px;
      background: url(../img/common/icon/ico_close.svg) no-repeat;
      left: 0;
      bottom: 0;
    }
  }
  &__slider{
    max-width: 1054px;
    width: 100vw;
    padding: 20px 0px;
    margin: auto;
    text-align: left;
    border-radius: 5px;
    position: relative;
    // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //   max-width: 954px;
    // }
    .slick-track {
      font-size: 0;
      img{
        margin: 0 auto;
        width: 100%;
      }
    }
    .slick-arrow{
      top: calc(50% - 10px);
      width: 40px;
      height: 40px;
      background: #3e3e3e url(../img/common/arl_w.svg) center/8px 13px no-repeat;
      z-index: 10;
      transition: 1s;
      opacity: 0.8;
      &.disable{
        opacity: 0;
        cursor: default;
      }
    }
    .slick-prev{
      left: -50px;
    }
    .slick-next{
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      right: -50px;
    }
    .slick-prev, .slick-next{
      &:before{
        content: '';
      }
    }

    .slick-track{
      display: table;
    }

    .slick-slide{
      display: table-cell !important;
      vertical-align: middle;
      float: none;
      position: relative;
      span{
        background: #fff;
        text-align: left;
        padding: 9px 10px;
        margin: 0;
        font-size: 14px;
        display: block;
      }
      .inside{
        padding: 0 15px;
      }
    }
  }
  .slick-slider{
    margin-bottom: 0;
  }
}