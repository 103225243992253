@charset "UTF-8";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
p {
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input, textarea {
  margin: 0;
  font-size: 100%;
  resize: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}
dl, dt, dd, th, td {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  /*font-size: 100%;*/
  font-weight: normal;
  margin: 0;
  padding: 0;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, dialog {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
  max-width:100%;
  height:auto;
}
table img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}
*, *:before, *:after {
  @include add_prefix(box-sizing, border-box);
  @if $font_smooth {
    @include font-smooth();
  }
}
*:focus {outline: none!important;}
label, select, button {cursor: pointer;}

// Remove select appearance for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }
}
@media screen\0 {
  select::-ms-expand {
    display: none;
  }
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
  outline: none;
  transition:0.3s;
  @include PC {
    &:hover {
      text-decoration: none;
      color: #aaa;
      opacity: 0.5;
    }
  }
}
a:link, a:visited, a:active {
  color: $color_link;
  text-decoration: none;
}
/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {@include ffN;}
.ffM {@include ffM;}
.ffYG {@include ffYG;}
.ffYM {@include ffYM;}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}
/*-----------------------------------------------------------
PAGE BOOT
------------------------------------------------------------*/
/* WordPress */
.wp-pagenavi {
  clear: both;
  text-align: center;
  font-family:Arial;
  font-size:14px;
}
.wp-pagenavi span, .wp-pagenavi a {
  line-height:30px;
  display:inline-block;
  margin:0 4px;
  color:#666;
  text-align:center;
}
.wp-pagenavi a, .wp-pagenavi span.current {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  width:30px;
  height:30px;
  background:#ededed;
}

.wp-pagenavi a:hover, .wp-pagenavi span.current {
  background: #1489c3;
  color:#fff;
}
.wp-pagenavi .pages {
  margin-right:15px;
}
/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
html {
  font-size: 62.5%;
  @include add_prefix(text-size-adjust, 100%);
  @if $font_feature_settings_palt {
    @include add_prefix(font-feature-settings, "palt");
  }
}
body {
  font-family: $noto_se;
  @include font-size(16);
  width: 100%;
  color: $color_body;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  letter-spacing: 0;
  height: 100%;
  word-break: break-word;
}

/*-----------------------------------------------------------
Container
------------------------------------------------------------*/
.container {
  max-width: $widthcm;
  padding: 0 15px;
  margin: 0 auto;
}
/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.sp, .SP {
  @include min-screen(768px) {display: none!important;}
}

.pc, .PC {
  @include max-screen(767px) {display: none!important;}
}

.wcm {
  width: $widthcm;
  margin:auto;
}
/*-----------------------------------------------------------
Some stuff from bootstrap
------------------------------------------------------------*/
.fade { opacity: 0; @include transition_c(opacity 0.15s linear); }
.fade.in { opacity: 1; }
.collapse { display: none; }
.collapse.in { display: block; }
tr.collapse.in { display: table-row; }
tbody.collapse.in { display: table-row-group; }
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include add_prefix(transition-property, "height, visibility");
  @include add_prefix(transition-duration, 0.35s);
  @include add_prefix(transition-timing-function, ease);
}
.modal-open {
  overflow: hidden;
}
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  .close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.2;
    filter: alpha(opacity=20);
    &:hover,
    &:focus {
      color: #000000;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }
  }
  button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
  }
}
.modal.fade .modal-dialog {
  @include transform_c(translate(0, -25%));
  @include transition_c(transform 0.3s ease-out);
}
.modal.in .modal-dialog {
  @include transform_c(translate(0, 0));
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  @include add_prefix(box-shadow, 0 3px 9px rgba(0, 0, 0, 0.5));
  @include add_prefix(background-clip, padding-box);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
}
.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0);
}
.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modal-header .close { margin-top: -2px; }
.modal-title {
  margin: 0;
  line-height: 1.42857143;
}
.modal-body {
  position: relative;
  padding: 15px;
}
.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modal-footer .btn-group .btn + .btn { margin-left: -1px; }
.modal-footer .btn-block + .btn-block { margin-left: 0; }
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content { @include add_prefix(box-shadow, 0 5px 15px rgba(0, 0, 0, 0.5)); }
  .modal-sm { width: 300px; }
}
@media (min-width: 992px) {
  .modal-lg { width: 900px; }
}

// common
//common header, footer button
.btnYellow{
  display: block;
  width: 100%;
  max-width: 160px;
  height: 80px;
  background: $yellow;
  @include font(18,31,0,600);
  color: #fff;
  margin: 0;
  padding: 10px;
  span{
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
  }
  @include PC {
    &:hover{
      opacity: 1;
      background: rgba(196,146,67,.7);
    }
  }
}
.btn{
  display: block;
  width: 100%;
  max-width: 280px;
  height: 72px;
  background: $green;
  -webkit-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5); 
  box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.5);
  @include font(17,19,0,500);
  color: #fff;
  margin: 0 auto;
  border-radius: 72px;
  padding: 10px;
  overflow: hidden;
  span{
    width: 100%;
    height: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 52px;
    border: 1px solid #fff;
    position: relative;
    &:before,&:after{
      content: '';
      height: 6px;
      width: calc(100% - 52px);
      background: $green;
      position: absolute;
      top: -3px;
      left: 25px;
      transition: all 0.3s;
    }
    &:after{
      top: auto;
      bottom: -3px;
    }
  }
  @include HOVER {
    &:hover{
      opacity: 1;
      span{
        &:before{
          width: 0;
        }
        &:after{
          width: 0;
          left: 100%;
        }
      }
    }
  }
}

.title{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 427px;
  position: relative;
  padding: 22px 47px;
  &:before,&:after{
    content: '';
    width: 47px;
    height: 47px;
    position: absolute;
    background: url(../img/common/icon/ico_conner.svg) 0 0/ 47px 47px no-repeat;
  }
  &:before{
    top: 0;
    left: 0;
  }
  &:after{
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
  }
  &__en{
    @include font(24,24,0,500);
    color: rgba(51,51,51,.5);
    margin-bottom: 4px;
  }
  &__jp{
    @include font(40,50,0,500);
  }
  &--vertical{
    width: 138px;
    padding: 66px 22px 66px 35px;
    flex-direction: row;
    align-items: flex-start;
    .title__en{
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      margin-bottom: 0;
    }
    .title__jp{
      -webkit-writing-mode: vertical-rl;
      -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
      text-orientation: upright;
      margin-right: 8px;
      font-size: 38px;
    }
  }
  @include SP{
    width: 280px;
    padding: 11px 20px;
    &:before,&:after{
      width: 27px;
      height: 27px;
      background: url(../img/common/icon/ico_conner.svg) 0 0/ 27px 27px no-repeat;
    }
    &__en{
      @include font(16,16,0,500);
    }
    &__jp{
      @include font(28,25,0,500);
    }
    &--vertical{
      width: 80px;
      padding: 22px 11px 21px 18px;      
      .title__jp{
        font-size: 26px;
      }
    }
  }
}
